import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const EditJobs = ({ refOpen, editJobsData, seteditJobsData, token, baseUrl }) => {

    const navigate = useNavigate();
    const refClose = useRef(null);
    const [geoIds, setGeoIds] = useState([]);
    const [pIds, setPIds] = useState([]);
    const [helperIds, setHelperIds] = useState([]);
    const [incIds, setIncIds] = useState([]);
    const [vehicleIds, setVehicleIds] = useState([]);
    

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const [geoRes, pRes, helperRes, incRes, vehicleRes] = await Promise.all([
                    fetch(`${baseUrl}/sub-wards`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }),
                    fetch(`${baseUrl}/driver`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }),
                    fetch(`${baseUrl}/helper`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }),
                    fetch(`${baseUrl}/inc`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }),
                    fetch(`${baseUrl}/vehicles`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }),
                ]);

                const geoData = await geoRes.json();
                const pData = await pRes.json();
                const helperData = await helperRes.json();
                const incData = await incRes.json();
                const vehicleData = await vehicleRes.json();

                if (geoData.statusCode === 401 || pData.statusCode === 401 || helperData.statusCode === 401 || incData.statusCode === 401 || vehicleData.statusCode === 401
                    || geoData.statusCode === 203 || pData.statusCode === 203 || helperData.statusCode === 203 || incData.statusCode === 203 || vehicleData.statusCode === 203) {
                    navigate('/login');
                } else {
                    setGeoIds(geoData.data);
                    setPIds(pData.data);
                    setHelperIds(helperData.data);
                    setIncIds(incData.data);
                    setVehicleIds(vehicleData.data);
                }
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchOptions();
    }, [baseUrl, token, navigate]);

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        seteditJobsData({ ...editJobsData, [name]: value });
    }

    const editSubmit = async (e) => {
        e.preventDefault();

        try {

            const response = await fetch(`https://apis.ecowrap.in/b2g/internal-team/api/v1/collection-assignment`, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(editJobsData),
            });

            const data = await response.json();

            if (data.statusCode === 200) {
                Swal.fire({
                    icon: 'success',
                    text: "Data updated successfully!",
                    confirmButtonText: 'OK'
                });

                seteditJobsData({
                    "assignment_id": "",
                    "geo_id": "",
                    "p_id": "",
                    "helper_id": "",
                    "inc_id": "",
                    "vehicle_id": "",
                    "category": "",
                    "status": "",
                    "collection_date": ""
                })
                refClose.current.click();
            } else if (data.statusCode === 401 || data.statusCode === 203) {
                navigate('/login');
            } else {
                throw new Error(data.message || "Failed to update the job");
            }
        } catch (error) {
            console.error("Error updating job:", error.message);
            alert("Error updating job: " + error.message);
        }
    };




    return (
        <>
            <button ref={refOpen} type="button" className="btn d-none" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas1" aria-controls="depOffCanvas1"></button>

            <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="depOffCanvas1" aria-labelledby="depOffCanvas1">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Edit Data</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refClose}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form onSubmit={editSubmit} className="row g-3">
                            <div className="col-md-12 text-left">

                                <label className="form-label font-weight-bold" htmlFor="geoIds">Area </label>

                                <select className='form-select' value={editJobsData?.geo_id} name="geo_id" id="geoIds" onChange={handleChange}>
                                    <option className='form-control' value="">Select Geo ID</option>
                                    {geoIds.map((id) => (
                                        <option key={id} value={id?.geo_id}>
                                            {id?.area_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-12 text-left">

                                <label className="form-label font-weight-bold" htmlFor="pIds">Driver </label>
                                <select className='form-select' value={editJobsData?.p_id} name='p_id' id="pIds" onChange={handleChange}>
                                    <option value="">Select P ID</option>
                                    {pIds.map((id) => (
                                        <option key={id} value={id.p_id}>
                                            {id.p_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-12 text-left">

                                <label className="form-label font-weight-bold" htmlFor="helperIds">Helper  </label>
                                <select className='form-select' value={editJobsData?.helper_id} name='helper_id' id="helperIds" onChange={handleChange}>
                                    <option value="">Select Helper ID</option>
                                    {helperIds.map((id) => (
                                        <option key={id} value={id.helper_id}>
                                            {id.helper_name}
                                        </option>
                                    ))}

                                </select>
                            </div>

                            <div className="col-md-12 text-left">
                                <label className="form-label font-weight-bold" htmlFor="incIds">Inc  </label>
                                <select className='form-select' value={editJobsData?.inc_id} name="inc_id" id="incIds" onChange={handleChange}>
                                    <option value="">Select Inc ID</option>
                                    {incIds.map((id) => (
                                        <option key={id} value={id?.inc_id}>
                                            {id?.inc_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-12 text-left">

                                <label className="form-label font-weight-bold" htmlFor="vehicleIds" >Vehicle  </label>
                                <select className='form-select' value={editJobsData?.vehicle_id} name="vehicle_id" id="vehicleIds" onChange={handleChange}>
                                    <option value="">Select Vehicle ID</option>
                                    {vehicleIds.map((id) => (
                                        <option key={id} value={id.vehicle_id}>
                                            {id.vehicle_name}
                                        </option>
                                    ))}
                                </select>

                            </div>
                            <div className="col-md-12 text-left">

                                <label className="form-label font-weight-bold" htmlFor="vehicleIds">Category </label>
                                <select className='form-select' value={editJobsData?.category} name="category" id="vehicleIds" onChange={handleChange}>
                                    <option value="">Select Category</option>
                                    <option value="Wet">Wet</option>
                                    <option value="Dry">Dry</option>
                                </select>

                            </div>
                            <div className="col-md-12 text-left">

                                <label className="form-label font-weight-bold" htmlFor="vehicleIds">Status </label>
                                <select className='form-select' value={editJobsData?.status} name='status' id="vehicleIds" onChange={handleChange}>
                                    <option value="">Select Status</option>
                                    <option value='Assigned'>Assigned</option>
                                    <option value='In Progress'>In Progress</option>
                                    <option value='On Hold'>On Hold</option>
                                    <option value='Failed'>Failed</option>
                                    <option value='Completed'>Completed</option>
                                </select>

                            </div>
                            <div className="col-md-12 text-left">

                                <label className="form-label font-weight-bold" htmlFor="vehicleIds">Collection Date </label>
                                <input className='form-control' onChange={handleChange} type='date' name='collection_date' value={editJobsData?.collection_date} />

                            </div>

                            <div className="col-md-12">
                                <button type="submit" className="btn btn-secondary w-100 btn-block rounded-pill"  >
                                    {/* {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"} */}
                                    submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div >
            </div >
        </>
    )
}

export default EditJobs