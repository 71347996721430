import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
function PostJob({ token, baseUrl, navigate }) {
    const location = useLocation().state;

    // const helperIds = location.helper || [];
    // const incIds = location.inc || [];
    // const vehicleIds = location.vehicle || [];
    // const pIds = location.driver || [];
    console.log(location.helper, "location.inc");

    const [helperIds, setHelperIds] = useState(location.helper || [])
    const [incIds, setincIds] = useState(location.inc || [])
    const [vehicleIds, setvehicleIds] = useState(location.vehicle || [])
    const [pIds, setPIds] = useState(location.driver || [])

    useEffect(() => {
        const helperFilter = helperIds.filter((e, i) => e.h_blocked === 0 && e.h_leave === 0 && e.h_deleted === 0)
        const incFilter = incIds.filter((e, i) => e.inc_blocked === 0 && e.inc_leave === 0 && e.inc_deleted === 0)
        const vehicleFilter = vehicleIds.filter((e, i) => e.is_leave === 0 && e.v_deleted === 0)
        const pFilter = pIds.filter((e, i) => e.is_blocked === 0 && e.is_leave === 0 && e.p_deleted === 0)
        setHelperIds(helperFilter)
        setPIds(pFilter)
        setincIds(incFilter)
        setvehicleIds(vehicleFilter)

    }, [])

    const [geoIds, setGeoIds] = useState([]);


    const [selectedGeoId, setSelectedGeoId] = useState('');
    const [selectedPId, setSelectedPId] = useState('');
    const [selectedHelperId, setSelectedHelperId] = useState('');
    const [selectedIncId, setSelectedIncId] = useState('');
    const [selectedVehicleId, setSelectedVehicleId] = useState('');
    const [category, setCategory] = useState('Dry');
    const [dateForJob, setDateForJob] = useState('')

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const [geoRes] = await Promise.all([
                    fetch(`${baseUrl}/sub-wards?ward_id=${location.ward}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }),
                ]);
                const geoData = await geoRes.json();
                if (geoData.statusCode === 401 || geoData.statusCode === 203) {
                    navigate('/login');
                } else {
                    setGeoIds(geoData.data);
                }
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchOptions();
    }, [baseUrl, token, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const jobData = {
            geo_id: selectedGeoId,
            p_id: selectedPId,
            helper_id: selectedHelperId,
            inc_id: selectedIncId,
            vehicle_id: selectedVehicleId,
            category,
            collection_date: dateForJob
        };

        try {
            const res = await fetch(`${baseUrl}/collection-assignment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(jobData),
            });
            const data = await res.json()

            if (data.statusCode === 401) return navigate('/login');

            if (data.statusCode === 200) {
                navigate('/')

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'Failed to post job',
                });
            }
        } catch (error) {
            console.error('Error posting job:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred while posting the job',
            });
        }
    };

    return (
        <div className="container mt-5 pe-4">
            <h2 className="mb-4">Post Job</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label className="form-label">Sub area</label>
                    <select
                        className="form-select"
                        value={selectedGeoId}
                        onChange={(e) => setSelectedGeoId(e.target.value)}
                    >
                        <option value="">Select sub area</option>
                        {geoIds.map((geo) => (
                            <option key={geo.geo_id} value={geo.geo_id}>
                                {geo.area_name + ' ' + geo.tag}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="mb-3">
                    <label className="form-label">Select Driver</label>
                    <select
                        className="form-select"
                        value={selectedPId}
                        onChange={(e) => setSelectedPId(e.target.value)}
                    >
                        <option value="">Select driver</option>
                        {pIds.map((p) => (
                            <option key={p.p_id} value={p.p_id}>
                                {p.p_name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="mb-3">
                    <label className="form-label">Helper </label>
                    <select
                        className="form-select"
                        value={selectedHelperId}
                        onChange={(e) => setSelectedHelperId(e.target.value)}
                    >
                        <option value="">Select Helper</option>
                        {helperIds.map((helper) => (
                            <option key={helper.helper_id} value={helper.helper_id}>
                                {helper.helper_name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="mb-3">
                    <label className="form-label">Inc </label>
                    <select
                        className="form-select"
                        value={selectedIncId}
                        onChange={(e) => setSelectedIncId(e.target.value)}
                    >
                        <option value="">Select Iec </option>
                        {incIds.map((inc) => (
                            <option key={inc.inc_id} value={inc.inc_id}>
                                {inc.inc_name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="mb-3">
                    <label className="form-label">Vehicle</label>
                    <select
                        className="form-select"
                        value={selectedVehicleId}
                        onChange={(e) => setSelectedVehicleId(e.target.value)}
                    >
                        <option value="">Select Vehicle </option>
                        {vehicleIds.map((vehicle) => (
                            <option key={vehicle.vehicle_id} value={vehicle.vehicle_id}>
                                {vehicle.vehicle_number}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="mb-3">
                    <label className="form-label">Category</label>
                    <select
                        className="form-select"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                    >
                        <option value="Dry">Dry</option>
                        <option value="Wet">Wet</option>
                    </select>
                </div>

                <div className="mb-3">
                    <label htmlFor="datePicker" className="form-label">Select Date</label>
                    <input type="date"
                        className="form-control"
                        id="datePicker"
                        value={dateForJob}
                        onChange={(e) => (setDateForJob(e.target.value))}
                    />
                </div>

                <button type="submit" className="btn btn-primary">
                    Post Job
                </button>
            </form>
        </div>
    );
}

export default PostJob;
