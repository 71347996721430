import React, { useState, useEffect, useRef } from 'react';
import './home.css'
import EditJobs from './EditJobs';
import Swal from 'sweetalert2';
function Home({ ward, driver, inc, helper, vehicle, token, baseUrl, navigate, setToken }) {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editJobsData, seteditJobsData] = useState({ assignment_id: '', geo_id: '', p_id: '', helper_id: '', inc_id: '', vehicle_id: '', category: "", status: "", collection_date: "" })

    const refOpen = useRef(null)

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        // Fetch jobs from the API
        const fetchJobs = async () => {
            try {
                const response = await fetch(`${baseUrl}/collection-assignment`, {
                    headers: {
                        'Authorization': `Bearer ${token}`, // Send token in Authorization header
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();

                if (data.statusCode === 200) {
                    setJobs(data.data);
                }
                else if (data.statusCode === 401 || data.statusCode === 203) {

                    navigate('/login');
                }
                else {
                    throw new Error('Failed to fetch jobs');
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchJobs();
    }, [token, baseUrl, navigate]);




    const dividedJobsOnWard = ward.map((wardItem) => {
        return {
            ward_id: wardItem.ward_id,
            ward_number: wardItem.ward_number,
            jobsForWard: jobs.filter((jobItem) => jobItem.ward_id === wardItem.ward_id),
        };
    });

    const dividedResourcesOnWard = ward.map((wardItem) => {
        return {
            ward_id: wardItem.ward_id,
            ward_number: wardItem.ward_number,
            driverForWard: driver.filter((driverItem) => driverItem.p_ward_id === wardItem.ward_id),
            helperForWard: helper.filter((helperItem) => helperItem.h_ward_id === wardItem.ward_id),
            incForWard: inc.filter((incItem) => incItem.inc_ward_id === wardItem.ward_id),
            vehicleForWard: vehicle.filter((vehicleItem) => vehicleItem.v_ward_id === wardItem.ward_id),
        };
    });
    console.log(inc, helper, vehicle, "dividedDriverOnWard");




    const editJobs = async (job) => {
        refOpen.current.click();

        seteditJobsData({
            "assignment_id": job.assignment_id,
            "geo_id": job.geo_id,
            "p_id": job.p_id,
            "helper_id": job.helper_id,
            "inc_id": job.inc_id,
            "vehicle_id": job.vehicle_id,
            "category": job.category,
            "status": job.status,
            "collection_date": formatDate(job.collection_date)
        })

    };
    console.log(dividedJobsOnWard, "job.collection_date");

    const deleteJobs = (jobId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await fetch(`https://apis.ecowrap.in/b2g/internal-team/api/v1/collection-assignment/${jobId}`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                    });

                    if (response.ok) {

                        Swal.fire({
                            icon: 'success',
                            text: "Job deleted successfully!",
                            confirmButtonText: 'OK',
                        });
                    } else {

                        Swal.fire({
                            icon: 'error',
                            text: "Failed to delete job. Please try again.",
                            confirmButtonText: 'OK',
                        });
                    }
                } catch (error) {

                    Swal.fire({
                        icon: 'error',
                        text: `An error occurred: ${error.message}`,
                        confirmButtonText: 'OK',
                    });
                }
            }
        });
    };



    const handleLogout = () => {
        localStorage.removeItem('sprvsrtkn');
        setToken(null);
    };



    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className='container-fluid'>
            <nav className="d-flex flex-row justify-content-between align-items-center">
                <div className="navbar-brand-home">
                    <img src='asset/ecowrap-logo.png' alt="Logo" />
                </div>
                <div className='col-0'>
                    <button className='btn btn-secondary' onClick={handleLogout}>Logout</button>
                </div>
            </nav>

            <main className='home-main mt-4'>
                <div className='d-flex justify-content-between  mb-4'>
                    <button className='btn btn-primary' onClick={() => navigate('/resources')}>Resources</button>
                </div>

                {dividedJobsOnWard.map((data, index) => (
                    <div key={index} style={{ marginBottom: "20px" }}>
                        <h2> Ward Number: {data.ward_number}</h2>
                        <div className='add-button-div  mb-4'>
                            <button className='btn btn-primary' onClick={() => navigate('/postJob', {
                                state: {
                                    ward: data.ward_id,
                                    inc: dividedResourcesOnWard[index].incForWard,
                                    helper: dividedResourcesOnWard[index].helperForWard,
                                    driver: dividedResourcesOnWard[index].driverForWard,
                                    vehicle: dividedResourcesOnWard[index].vehicleForWard
                                }
                            })}>Add Job</button>
                        </div>
                        {data?.jobsForWard.length > 0 ? (
                            <div className=' '>
                                <table className='table full-size table-striped table-hover' border="1" style={{ width: "100%", textAlign: "left" }}>
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Area Name</th>
                                            <th scope="col">Vehicle Number</th>
                                            <th scope="col">Inc Name</th>
                                            <th scope="col">Helper Name</th>
                                            <th scope="col">Driver Name</th>
                                            <th scope="col">Category</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Collection Date</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.jobsForWard.map((job, idx) => (
                                            <tr key={idx}>
                                                <th scope="row">{idx + 1}</th>
                                                <td>{job.area_name}</td>
                                                <td>{job.vehicle_number}</td>
                                                <td>{job.inc_name}</td>
                                                <td>{job.helper_name}</td>
                                                <td>{job.p_name}</td>
                                                <td>{job.category}</td>
                                                <td>{job.status}</td>
                                                <td>{formatDate(job.collection_date)}</td>
                                                <td>
                                                    <button onClick={() => editJobs(job)} className='btn btn-sm btn-success me-2'><i class="fa fa-pencil"></i></button>
                                                    <button onClick={() => deleteJobs(job.assignment_id)} className='btn btn-sm btn-danger'><i class="fa fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className='d-flex align-items-center gap-3 mob-size flex-column'>
                                    {data.jobsForWard.map((job, idx) =>

                                        <div className='job-card mob-size rounded '>
                                            <h5>{idx + 1}.</h5>
                                            <div className='d-flex flex-wrap justify-content-between ps-3'>
                                                <p ><b>Area Name : </b>{job.area_name}</p>
                                                <p><b>Vehicle Number : </b>{job.vehicle_number}</p>

                                                <p><b>Inc Name : </b>{job.inc_name}</p>
                                                <p><b>Helper Name : </b>{job.helper_name}</p>

                                                <p><b>Driver Name : </b>{job.p_name}</p>
                                                <p><b>Category : </b>{job.category}</p>

                                                <p><b>Status : </b>{job.status}</p>
                                                <p><b>Collection Date : </b>{formatDate(job.collection_date)}</p>
                                            </div>
                                            <div className='d-flex mt-3 justify-content-end gap-2'>
                                                <button onClick={() => editJobs(job)} className='btn btn-sm btn-success me-2'><i class="fa fa-pencil"></i></button>
                                                <button onClick={() => deleteJobs(job.assignment_id)} className='btn btn-sm btn-danger'><i class="fa fa-trash"></i></button>
                                            </div>

                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <p>No matches found</p>
                        )}
                    </div>
                ))}
            </main>

            <EditJobs refOpen={refOpen} editJobsData={editJobsData} seteditJobsData={seteditJobsData} token={token} baseUrl={baseUrl} />
        </div>

    );
}

export default Home;
