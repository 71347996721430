import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const EditDriver = ({ ward, refOpen, editDriverData, seteditDriverData, token, baseUrl }) => {

    const navigate = useNavigate();
    const refClose = useRef(null);

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
         seteditDriverData({ ...editDriverData, [name]: value });
    }

    const editSubmit = async (e) => {
        e.preventDefault();

        try {
             
    const response = await fetch(`https://apis.ecowrap.in/b2g/internal-team/api/v1/driver/${editDriverData.driver_id}`, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "is_leave": `${editDriverData.is_leave}`,
                    "is_blocked": `${editDriverData.is_blocked}`,
                    "ward_id": editDriverData.ward_id
                }),
            });

            const data = await response.json();

            if (data.statusCode === 200) {
                Swal.fire({
                    icon: 'success',
                    text: "Data updated successfully!",
                    confirmButtonText: 'OK'
                });


                refClose.current.click();
            } else if (data.statusCode === 401 || data.statusCode === 203) {
                navigate('/login');
            } else {
                throw new Error(data.message || "Failed to update the job");
            }
        } catch (error) {
            console.error("Error updating job:", error.message);
            alert("Error updating job: " + error.message);
        }
    };




    return (
        <>
            <button ref={refOpen} type="button" className="btn d-none" data-bs-toggle="offcanvas" data-bs-target="#editDriver" aria-controls="editDriver"></button>

            <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="editDriver" aria-labelledby="editDriver">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Edit Data</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refClose}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form onSubmit={editSubmit} className="row g-3">
                            <div className="col-md-12 text-left">
                                <label className="form-label font-weight-bold" htmlFor="driver_leave">Resigned </label>
                                <select className='form-select' value={editDriverData.is_leave} name="is_leave" id="driver_leave" onChange={handleChange}>
                                    <option className='form-control' value="">Select</option>
                                    <option value='0'>
                                        No
                                    </option>
                                    <option value='1'>
                                        Yes
                                    </option>
                                </select>
                            </div>

                            <div className="col-md-12 text-left">
                                <label className="form-label font-weight-bold" htmlFor="driver_blocked">Blocked </label>
                                <select className='form-select' value={editDriverData.is_blocked} name="is_blocked" id="driver_blocked" onChange={handleChange}>
                                    <option className='form-control' value="">Select</option>
                                    <option value='0'>
                                        No
                                    </option>
                                    <option value='1'>
                                        Yes
                                    </option>
                                </select>
                            </div>

                            <div className="col-md-12 text-left">

                                <label className="form-label font-weight-bold" htmlFor="editDriverWards">Ward </label>

                                <select className='form-select' value={editDriverData.ward_id} name="ward_id" id="editDriverWards" onChange={handleChange}>
                                    <option className='form-control' value="">Select ward</option>
                                    {ward.map((id) => (
                                        <option key={id?.ward_id} value={id?.ward_id}>
                                            {id?.ward_number}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-md-12">
                                <button type="submit" className="btn btn-secondary w-100 btn-block rounded-pill"  >
                                    {/* {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"} */}
                                    submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div >
            </div >
        </>
    )
}

export default EditDriver