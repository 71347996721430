import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const EditVehicle = ({ ward, refOpen, editVehicleData, seteditVehicleData, token, baseUrl }) => {

    const navigate = useNavigate();
    const refClose = useRef(null);

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        seteditVehicleData({ ...editVehicleData, [name]: value });
    }

    const editSubmit = async (e) => {
        e.preventDefault();

        try {
 
            const response = await fetch(`https://apis.ecowrap.in/b2g/internal-team/api/v1/vehicles`, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(editVehicleData),
            });

            const data = await response.json();

            if (data.statusCode === 200) {
                Swal.fire({
                    icon: 'success',
                    text: "Data updated successfully!",
                    confirmButtonText: 'OK'
                });


                refClose.current.click();
            } else if (data.statusCode === 401 || data.statusCode === 203) {
                navigate('/login');
            } else {
                throw new Error(data.message || "Failed to update the job");
            }
        } catch (error) {
            console.error("Error updating job:", error.message);
            alert("Error updating job: " + error.message);
        }
    };




    return (
        <>
            <button ref={refOpen} type="button" className="btn d-none" data-bs-toggle="offcanvas" data-bs-target="#editVehicle" aria-controls="editVehicle"></button>

            <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="editVehicle" aria-labelledby="editVehicle">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Edit Data</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refClose}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form onSubmit={editSubmit} className="row g-3">
                            <div className="col-md-12 text-left">

                                <label className="form-label font-weight-bold" htmlFor="vehicleIds">Vehicle Name </label>
                                <input className='form-control' onChange={handleChange} type='text' name='vehicle_name' value={editVehicleData.vehicle_name} />

                            </div>

                            <div className="col-md-12 text-left">

                                <label className="form-label font-weight-bold" htmlFor="vehicleIds">Vehicle Number </label>
                                <input className='form-control' onChange={handleChange} type='text' name='vehicle_number' value={editVehicleData.vehicle_number} />

                            </div>


                            <div className="col-md-12 text-left">

                                <label className="form-label font-weight-bold" htmlFor="vehicletype">Ward </label>

                                <select className='form-select' value={editVehicleData.vehicle_type} name="vehicle_type" id="vehicletype" onChange={handleChange}>
                                    <option className='form-control' value="">Select type</option>
                                    <option value='wet'>
                                        wet
                                    </option>
                                    <option value='dry'>
                                        dry
                                    </option>
                                </select>
                            </div>

                            <div className="col-md-12 text-left">

                                <label className="form-label font-weight-bold" htmlFor="vehicleWard">Ward </label>

                                <select className='form-select' value={editVehicleData.v_ward_id} name="v_ward_id" id="vehicleWard" onChange={handleChange}>
                                    <option className='form-control' value="">Select ward</option>
                                    {ward.map((id) => (
                                        <option key={id?.ward_id} value={id?.ward_id}>
                                            {id?.ward_number}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-md-12">
                                <button type="submit" className="btn btn-secondary w-100 btn-block rounded-pill"  >
                                    {/* {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"} */}
                                    submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div >
            </div >
        </>
    )
}

export default EditVehicle