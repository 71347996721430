import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const AddVehicle = ({ refOpen, ward, token, baseUrl }) => {

    const navigate = useNavigate();
    const refClose = useRef(null);
    const [addVehicle, setAddVehicle] = useState({ vehicle_name: '', vehicle_number: '', vehicle_type: '',v_ward_id:'' })

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setAddVehicle({ ...addVehicle, [name]: value });
    }


    const editSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`https://apis.ecowrap.in/b2g/internal-team/api/v1/vehicles`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(addVehicle),
            });

            const data = await response.json();

            if (data.statusCode === 200) {
                Swal.fire({
                    icon: 'success',
                    text: "Data submitted successfully!",
                    confirmButtonText: 'OK',
                });

                refClose.current.click();
            } else if (data.statusCode === 401 || data.statusCode === 203) {
                navigate('/login');
            } else {
                throw new Error(data.message || "Failed to submit data");
            }
        } catch (error) {
            console.error("Error submitting data:", error.message);
            alert("Error submitting data: " + error.message);
        }
    };





    return (
        <>
            <button ref={refOpen} type="button" className="btn d-none" data-bs-toggle="offcanvas" data-bs-target="#vehicleCanvas" aria-controls="vehicleCanvas">Add Vehicle</button>

            <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="vehicleCanvas" aria-labelledby="vehicleCanvas">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Add Vehicle</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refClose}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form onSubmit={editSubmit} className="row g-3">

                            <div className="col-md-12 text-left">

                                <label className="form-label font-weight-bold" htmlFor="vehicleIds">Vehicle Name </label>
                                <input className='form-control' onChange={handleChange} type='text' name='vehicle_name' value={addVehicle.vehicle_name} />

                            </div>

                            <div className="col-md-12 text-left">

                                <label className="form-label font-weight-bold" htmlFor="vehicleIds">Vehicle Number </label>
                                <input className='form-control' onChange={handleChange} type='text' name='vehicle_number' value={addVehicle.vehicle_number} />

                            </div>


                            <div className="col-md-12 text-left">

                                <label className="form-label font-weight-bold" htmlFor="vehicleType">Type </label>

                                <select className='form-select' value={addVehicle.vehicle_type} name="vehicle_type" id="vehicleType" onChange={handleChange}>
                                    <option className='form-control' value="">Select type</option>
                                     
                                        <option   value='wet'>
                                           wet
                                        </option>
                                        <option   value='dry'>
                                           dry
                                        </option>
                                   
                                </select>
                            </div>

                            <div className="col-md-12 text-left">

                                <label className="form-label font-weight-bold" htmlFor="vehicleWard">Ward </label>

                                <select className='form-select' value={addVehicle.v_ward_id} name="v_ward_id" id="vehicleWard" onChange={handleChange}>
                                    <option className='form-control' value="">Select ward</option>
                                    {ward.map((id) => (
                                        <option key={id} value={id?.ward_id}>
                                            {id?.ward_number}
                                        </option>
                                    ))}
                                </select>
                            </div>


                            <div className="col-md-12">
                                <button type="submit" className="btn btn-secondary w-100 btn-block rounded-pill"  >
                                    {/* {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"} */}
                                    submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div >
            </div >
        </>
    )
}

export default AddVehicle