import { Routes, Route, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './App.css';
import Login from './login';
import Home from './home';
import PostJob from './postJob';
import Resources from './Resources/Resources';
function App() {
  // const baseUrl = 'https://apis.ecowrap.in/b2g/supervisor'
  const baseUrl = 'https://apis.ecowrap.in/b2g/internal-team/api/v1'
  const [token, setToken] = useState(localStorage.getItem('sprvsrtkn'))

  const [geoIds, setGeoIds] = useState([]);
  const [pIds, setPIds] = useState([]);
  const [helperIds, setHelperIds] = useState([]);
  const [incIds, setIncIds] = useState([]);
  const [vehicleIds, setVehicleIds] = useState([]);
  const [wards, setWards] = useState([]);


  const navigate = useNavigate()
  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  }, [token, navigate])


  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [geoRes, pRes, helperRes, incRes, vehicleRes, wardRes] = await Promise.all([
          fetch(`${baseUrl}/sub-wards`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }),
          fetch(`${baseUrl}/driver`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }),
          fetch(`${baseUrl}/helper`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }),
          fetch(`${baseUrl}/inc`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }),
          fetch(`${baseUrl}/vehicles`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }),
          fetch(`https://apis.ecowrap.in/b2g/internal-team/api/v1/assigned-wards`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }),
        ]);

        const geoData = await geoRes.json();
        const pData = await pRes.json();
        const helperData = await helperRes.json();
        const incData = await incRes.json();
        const vehicleData = await vehicleRes.json();
        const wardData = await wardRes.json();


        if (geoData.statusCode === 401 || pData.statusCode === 401 || helperData.statusCode === 401 || incData.statusCode === 401 || vehicleData.statusCode === 401
          || geoData.statusCode === 203 || pData.statusCode === 203 || helperData.statusCode === 203 || incData.statusCode === 203 || vehicleData.statusCode === 203) {
          navigate('/login');
        } else {
          setGeoIds(geoData.data);
          setPIds(pData.data);
          setHelperIds(helperData.data);
          setIncIds(incData.data);
          setVehicleIds(vehicleData.data);
          setWards(wardData?.data)
        }
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, [baseUrl, token, navigate]);

  return (
    <div className='app'>
      <Routes>
        <Route path="/login" element={<Login setToken={setToken} baseUrl={baseUrl} navigate={navigate} />} />
        <Route path="/" element={<Home ward={wards} driver={pIds} inc={incIds} helper={helperIds} vehicle={vehicleIds} token={token} setToken={setToken} baseUrl={baseUrl} navigate={navigate} />} />
        <Route path="/postJob" element={<PostJob token={token} baseUrl={baseUrl} navigate={navigate} />} />
        <Route path="/resources" element={<Resources ward={wards} baseUrl={baseUrl} token={token} driver={pIds} inc={incIds} helper={helperIds} vehicle={vehicleIds} />} />
      </Routes>
    </div>
  );
}

export default App;
